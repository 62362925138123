// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);
$my-blue-palette: (
  50: #e4e9ee,
  100: #bcc9d6,
  200: #8fa5ba,
  300: #62809e,
  400: #41658a,
  500: #1f4a75,
  600: #1b436d,
  700: #173a62,
  800: #123258,
  900: #0a2245,
  A100 : #7cabff,
  A200 : #498bff,
  A400 : #166aff,
  A700 : #005bfc,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$tenders360-primary: mat.define-palette($my-blue-palette);
$tenders360-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$tenders360-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$tenders360-theme: mat.define-light-theme((
  color: (
    primary: $tenders360-primary,
    accent: $tenders360-accent,
    warn: $tenders360-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($tenders360-theme);


// Theme variables, must be included before the libraries to allow overriding defaults
@tailwind base;
@tailwind components;
@tailwind utilities;

// 3rd party libraries

// Theme customization
@import './assets/scss/app.scss';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mdc-line-ripple::before {
  border-color: white !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent;
}

.mat-mdc-text-field-wrapper {
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}


.mat-mdc-unelevated-button {
  padding: 25px !important;
}
