
// @import 'variables';
@import 'mixins';
@import 'template/theme.scss';
@import 'template/layout.scss';
@import 'template/header.scss';
@import 'template/side-nav.scss';

/*****************/
/***** Apps *****/
/***************/
@import 'apps/project.scss';
@import 'apps/full-calendar.scss';
@import 'ui-element/calendar.scss';

// snackbars colors
//success
$snackbar-success-background-color: #eaf7ee;
$snackbar-success-color: #3b4655;
$snackbar-success-icon-color: #39b059;
$snackbar-success-border-color: #bcdfc3;
//error
$snackbar-error-background-color: #f3c3c3;
$snackbar-error-color: #3b4655;
$snackbar-error-icon-color: #fb6666;
$snackbar-error-border-color: #fc8383;
//info
$snackbar-info-background-color: #95c5dd;
$snackbar-info-color: #3b4655;
$snackbar-info-border-color: #47c0fc;
$snackbar-info-icon-color: #039be5;


.error-snackbar {
  color: $snackbar-error-color !important;
  border: 1px solid $snackbar-error-border-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;

  // error snackbar custom style
  background-color: $snackbar-error-background-color !important;
  margin-top: 7px !important;
  --mat-mdc-snack-bar-button-color: unset !important;
  --mdc-snackbar-container-color: unset !important;
  --mdc-snackbar-supporting-text-color: unset !important;

  .divButtonError,
  .mat-mdc-button {
    background-color: $snackbar-error-background-color !important;
    color: $snackbar-error-color !important;
    padding: 3px 10px !important;
    cursor: pointer !important;
    font-size: 10px !important;
    margin: 0 20px !important;
    line-height: 15px !important;
  }

  .mat-mdc-simple-snack-bar {
    display: flex !important;
    justify-content: center !important;
  }

  .divButtonWaiting {
    visibility: hidden !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .divButtonSuccess {
    visibility: hidden !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .custmized-snackbar-icon .mat-icon {
    color: $snackbar-error-icon-color;
  }
}

.success-message-snackbar {
  color: $snackbar-success-color !important;
  border: 1px solid $snackbar-success-border-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;

  // success snackbar custom style
  background-color: $snackbar-success-background-color !important;
  margin-top: 7px !important;
  --mat-mdc-snack-bar-button-color: unset !important;
  --mdc-snackbar-container-color: unset !important;
  --mdc-snackbar-supporting-text-color: unset !important;

  .divButtonSuccess,
  .mat-mdc-button {
    background-color: $snackbar-success-background-color !important;
    color: $snackbar-success-color !important;
    padding: 3px 10px !important;
    cursor: pointer !important;
    font-size: 10px !important;
    margin: 0 20px !important;
    line-height: 15px !important;
  }

  .mat-mdc-simple-snack-bar {
    display: flex !important;
    justify-content: space-between !important;

    span {
      text-align: center;
      width: 100%;
    }
  }

  .divButtonError {
    visibility: hidden !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .divButtonWaiting {
    visibility: hidden !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .custmized-snackbar-icon .mat-icon {
    color: $snackbar-success-icon-color;
  }
}

.info-message-snackbar {
  color: $snackbar-info-color !important;
  border: 1px solid $snackbar-info-border-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;

  background-color: $snackbar-info-background-color !important;
  margin-top: 7px !important;
  --mat-mdc-snack-bar-button-color: unset !important;
  --mdc-snackbar-container-color: unset !important;
  --mdc-snackbar-supporting-text-color: unset !important;

  .divButtonSuccess,
  .mat-mdc-button {
    background-color: $snackbar-info-background-color !important;
    color: $snackbar-info-color !important;
    padding: 3px 10px !important;
    cursor: pointer !important;
    font-size: 10px !important;
    margin: 0 20px !important;
    line-height: 15px !important;
  }

  .mat-mdc-simple-snack-bar {
    display: flex !important;
    justify-content: center !important;
  }

  .divButtonError {
    visibility: hidden !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .divButtonWaiting {
    visibility: hidden !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .custmized-snackbar-icon .mat-icon {
    color: $snackbar-info-icon-color;
  }
}

.custmized-snackbar-text {
  margin-right: 100px !important;
}

.customized-snackbar-container {
  justify-content: flex-start !important;
}
